.header-md::after {
	background-image: none;
}

ion-toolbar.dynamic-header {
	--min-height: var(--ion-toolbar-min-height);
	--ion-toolbar-background: transparent;
	--ion-toolbar-color: var(--ion-color-dark);
	margin-top: -15px;
}

#main > ion-content {
	z-index: 5;
}

.ion-page {
    min-width: 285px;
}

.dash-layout {
	background-image: url(/static/media/dashboard-01.21341c1a.png);
	background-repeat: no-repeat;
	background-position: 15% 100%;
	background-size: auto 100%;
}

.dash-layout::before {
	content: '';
	display: block;
	position: absolute;
	left: 0; top: 0; right: 0;
	bottom: 40%;
	z-index: 0;
	background-image: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0));
}

.dash-card {
	position: absolute;
	left: 30px; right: 30px; bottom: 0;
	z-index: 1;
	--background: #fff;
	border-radius: var(--ion-card-radius);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	padding-bottom: 35%;
	background-image: url(/static/media/restaurant.27dc9580.png);
	background-repeat: no-repeat;
	background-position: 50% 135%;
	background-size: 60% auto;
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
	margin-top: 0;
	margin-bottom: 0;
}

.dash-menu {
	padding: 0 64px;
}

.dash-menu .list-md {
	background: transparent;
}

.dash-menu ion-item {
	--color: var(--ion-color-primary);
	--background: trasparent;
	--background-hover: trasparent;
	--min-height: 32px;
}

.dash-card ion-card-title, .dash-menu ion-item {
	font-weight: bold;
}

.dash-menu ion-label {
	text-align: center;
}

.validate-button-wrapper {
    height: 45px;
}

.validate-button-wrapper ion-row {
    width: 165px;
    margin: 0 auto;
    border-radius: 20px;
    border: 1px solid var(--ion-color-gray);
    margin-bottom: 25px;
    color: var(--ion-color-gray);
}

.validate-button-wrapper ion-row ion-col div ion-icon {
    font-size: 19px;
    color: var(--ion-color-gray);
}

.validate-button-wrapper ion-row ion-col.verified-right{
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    justify-self: flex-start;
    flex-grow: 3;
}

.content-spinner {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 6;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.3);
}

.transparent {
  background: rgba(0,0,0,0);
}

/*.loyalty-content {
	text-align: center;
	padding: 20px;
}

.qr-holder {
	margin-top: 40px;
}

.loyalty-content h2.points {
	margin-top: 0px;
	padding-top: 0px;
	font-size: 60px;
	font-weight: bold;
}

.loyalty-content.scan h2 {
	margin-top: 20px;
}

.loyalty-content h2 {
	margin-top: 0px;
	font-size: 15px;
	font-weight: bold;
	text-transform: uppercase;
}

.loyalty-content ion-note {
	display: block;
	margin-bottom: 20px;
	margin: 0 auto;
	width: 55%;
}

.loyalty-content ion-button {
	margin: 0 20px 20px;
}

.loyalty-content ion-card {
	position: relative;
}

.loyalty-content ion-card-title {
	font-weight: bold;
	font-size: 22px;
}

.loyalty-content ion-card-subtitle {
	text-transform: none;
}

.loyalty-content .cover {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--ion-color-dark);
	opacity: 0.6;
}

.loyalty-content .cover ion-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 50px;
	margin-left: -25px;
	margin-top: -25px;
}

.loyalty-image {
	--background: #fff;
	border-radius: 0px;
	padding-bottom: 35%;
	background-repeat: no-repeat;
	background-position: center top;
	background-size: 100% auto;
	box-shadow: none;
}

.loyalty-image-01 {
    background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding-bottom: 35%;
}

.noQrCode {
    display: flex;
	justify-content: center;
	margin-top: 20px;
}

.order-intro-modal > .modal-wrapper {
	--max-height: 450px;
	width: 300px;
	border-radius: 15px;
}

.order-intro-modal > .modal-wrapper {
	height: 390px;
	width: 300px;
	border-radius: 15px;
}

.order-intro-modal > .modal-wrapper > .ion-page {
	overflow: auto;
	justify-content: center;
}

.header {
	background-color: var(--ion-color-primary);
}

.modal-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 15px
}

.modal-button {
	margin-top: 35px;
	padding: 0 15px;
}
*/

/* second tab */

.loyalty-content.scan {
	padding-bottom: 60px;
}

.loyalty-content.scan h2 {
	margin-top: 20px;
	margin-bottom: 5px;
}

.noQrCode {
    display: flex;
	justify-content: center;
	margin-top: 20px;
}

/* end second tab */

.points-tab {
	background: transparent url(/static/media/history-details.0ddf4aa7.png) no-repeat 100% 125%;
	background-size: 120% auto;
	z-index: 1;
}

.points-tab::after {
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	display: block;
	content: '';
	background-image: linear-gradient(#fff 65%, rgba(255,255,255,0) 105%);
}

.verified-content {
	width: 190px;
	margin: 0 auto;
}

.loyalty-title-wrap {
	text-align: center;
	padding-bottom: 30px;
}

.bold {
	font-weight: 600;
}
.tabs {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
  z-index: 0;

}
.tabs .segment-holder {
  position: sticky;
  position: -webkit-sticky;
  flex: 0 0 auto;
  top: 0;
  z-index: 2;
}

/* .tabs ion-segment-button {
  min-width: 70px;
} */

.tabs ion-segment-button {
	--indicator-color: transparent;
}

.tabs ion-slides, ion-slide {
  flex: 1 1 auto;
  margin: 0;
  height: calc(100vh - 163px);
}

.loyalty-bar > div {
	flex: 1 1 auto;
	text-align: left;
}

.loyalty-bar > div:first-child {
	flex: 0 0;
}

.loyalty-bar > div:last-child {
	text-align: right;
}

.loyalty-bar .loyalty-badge {
	transform: scale(0.6);
	margin: 0 0 0 24px;
}

.loyalty-bar .loyalty-balance {
	margin: 0 24px 0 0;
}
.loyalty-badge {
	width: var(--okx-loyalty-badge-width);
	position: relative;
	border-radius: 50%;
	padding: var(--okx-loyalty-badge-inner-padding);
	background-color: #fcba29;
	border: 2px solid #c58d13;
	margin: 20px auto;
}

.loyalty-badge > div {
	padding-top: calc(100% - 4px);
	border-radius: 50%;
	border: inherit;
}

.loyalty-badge > div::before, .loyalty-badge > div::after {
	content: '';
	display: block;
	position: absolute;
	right: 6px;
	bottom: 8px;
	background-color: #fcba29;
	width: 2px;
	height: 5px;
	transform: rotate(-40deg);
}

.loyalty-badge > div::after {
	right: 10px;
	bottom: 6px;
}

.loyalty-badge > div ion-icon {
	--lb-icon-offset: calc(var(--okx-loyalty-badge-width) /2 - var(--okx-loyalty-badge-inner-padding));
	position: absolute;
	left: var(--lb-icon-offset);
	top: var(--lb-icon-offset);
	font-size: 20px;
	margin: -9px 0 0 -8px;
	color: #c58d13;
}

.loyalty-bar {
	position: absolute;
	width: 100vw;
	height: var(--okx-loyalty-bar-height);
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 0 0 auto;
	background-color: #fff;
	box-shadow: 0 0 15px rgba(0,0,0,0.2);
	left: 0;
	bottom: 0;
	z-index: 2;
}

.input-avatar {
	display: none;
}

.profile-image-content {
	width: 96px;
	height: 96px;
	margin: auto;
}

.avatar-image-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 30px
}

.avatar-delete {
	margin-left: 81px;
	margin-top: -22px;
}

.mbsc-mobiscroll .mbsc-fr-hdr, .mbsc-mobiscroll .mbsc-fr-btn {
	color: var(--ion-color-primary);
}

.mbsc-mobiscroll .mbsc-cal-days > div {
	color: var(--ion-color-primary);
	border-color: var(--ion-color-primary);
}

.mbsc-mobiscroll.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt, .mbsc-mobiscroll.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover, .mbsc-mobiscroll .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
	background: var(--ion-color-primary-tint);
}

.mbsc-mobiscroll .mbsc-fr-btn.mbsc-active, .mbsc-mobiscroll.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
	background: var(--ion-color-primary-tint);
}

.mbsc-mobiscroll .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
	background: var(--ion-color-primary);
}

.date-picker-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.date-picker-label {
	font-size: 9px;
	color: var(--okx-normal-text-color);
	color: #959595;
	margin-left: 2px;
	transition: .1s ease-in;
}

.date-picker-label--red {
	color: var(--ion-color-primary);
}

.data-picker-input {
	border: 0;
	outline: 0;
	color: var(--ion-color-dark);
	padding: 10px 8px 10px 0;
}


.mbsc-material .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
	/* background: var(--ion-color-primary); */
	background: rgba(255, 26, 75, .9);
	color: #fff;
}

.mbsc-material .mbsc-fr-btn {
	color: var(--ion-color-primary);
}

.mbsc-ios .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
	background: var(--ion-color-primary);
	border-color: var(--ion-color-primary);
}

.mbsc-mobiscroll .mbsc-sc-whl-l {
	display: none;
}
.mbsc-comp.time-picker {
  width: 100%;
  color: var(--ion-color-dark);
  padding: 10px 8px 10px 0;
  border: 0;
  border-bottom: 1px solid;
  border-color: rgba(0,0,0,0.13);
  border-color: var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
  outline: none;
}

.mbsc-comp.time-picker::-webkit-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-comp.time-picker:-ms-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-comp.time-picker::-ms-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-comp.time-picker::placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-ios .mbsc-sc-itm-sel, .mbsc-ios .mbsc-sc-whl-gr-3d {
  /* color: #000; */
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
}

.mbsc-material .mbsc-sc-lbl {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
}

.mbsc-fr-btn1 .mbsc-fr-btn-e .mbsc-fr-btn {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
}

/* .mbsc-sc-itm .mbsc-btn-e .mbsc-sc-itm-sel {
  color: var(--ion-color-primary,#3880ff);
} */

.mbsc-material .mbsc-fr-btn-cont .mbsc-fr-btn {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
}

.mbsc-fr-btn-cont {
  display: flex !important;
  justify-content: space-between;
}

.mbsc-sc-itm-sel {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
  font-weight: 600;
}

.time-picker-label {
  font-size: 9px;
  color: var(--okx-normal-text-color);
  margin-left: 2px;
}

.mbsc-mobiscroll .mbsc-sc-lbl {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff); 
}

.mbsc-material .mbsc-sc-whl-l {
  display: none;
}

.mbsc-select-input.mbsc-control {
  width: 100%;
  color: var(--ion-color-dark);
  padding: 10px 8px 10px 0;
  border: 0;
  border-bottom: 1px solid;
  border-color: rgba(0,0,0,0.13);
  border-color: var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
  outline: none;
}

.mbsc-select-input.mbsc-control::-webkit-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-select-input.mbsc-control:-ms-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-select-input.mbsc-control::-ms-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-select-input.mbsc-control::placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-ios .mbsc-fr-btn {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
  font-size: 17px;
}

.select-picker-label {
  display: block;
  font-size: 9px;
  color: var(--okx-normal-text-color);
  margin-top: 10px;
  margin-left: 2px;
}

.mbsc-fr-btn-w .mbsc-fr-btn-s {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-cont {
  border: 1px solid rgba(0,0,0,0.13);
  border: 1px solid var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
}

.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
  color: #a3a3a3;
}

.mbsc-ios .mbsc-sc-itm-sel {
  color: #000;
  color: #3880ff !important;
  color: var(--ion-color-primary,#3880ff) !important;
}
.feedback-label {
    font-size: 10px;
    color: var(--ion-feedback-label);
    margin: 5px;
    font-weight: bold;
}
  
.star-holder {
    padding: 10px 0px;
}
  
.star {
    font-size: 26px;
    color: var(--ion-feedback-star);
    margin-right: 15px;
}
  
.box-holder-top {
    margin-top: 20px;
}

.separator {
    height: 1px;
    background-color: var(--ion-separator-color);
    margin: 0 5px;
}

.native-textarea.sc-ion-textarea-ios  {
  -webkit-padding-start: 5px;
          padding-inline-start: 5px;
}

.commentService {
  height: 50px;
}

.commentTech {
  height: 160px;
}

.item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h {
  margin-left: 2px;
}

.item.sc-ion-label-ios-h, .item .sc-ion-label-md-h {
  margin-left: 2px;
}

.history-content {
		text-align: center;
		padding: 20px;
}

.history-item {
		border-bottom: 1px solid var(--ion-separator-color);
		text-transform: uppercase;
}

.history-item h2 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 15px;
		font-weight: bold;
}

.history-item ion-col:first-child {
		text-align: left;
}

.history-item ion-col p:first-child {
		color: var(--ion-color-primary);
		font-size: 8px;
		margin-bottom: 8px;
		font-weight: bold;
}

.history-item ion-col p:last-child {
		color: var(--ion-color-medium);
		font-size: 9px;
		margin-top: 2px;
		margin-bottom: 10px;
}

.history-item ion-col:last-child p {
	display: flex;
    font-size: 15px;
    font-weight: 500;
    height: 100%;
    color: var(--ion-color-success);
    align-items: center;
    justify-content: flex-end;
}

.history-item ion-col:last-child p.redeem {
	/* text-align: right; */
	font-size: 15px;
	/* margin-top: 25px; */
	font-weight: 500;
	color: var(--ion-color-danger);
}

.order-button-wrapper {
	float: right;
}

.order-button-wrapper .order-button {
	padding: 3px 8px 2px 8px;
	display: inline-flex;
	justify-content: center;
	font-size: 9px;
}

.order-button-wrapper .order-button ion-icon {
	font-size: 10px;
}

.okx-box {
	border: 1px solid var(--okx-border-color);
	border-radius: var(--okx-box-radius);
	padding-left: var(--okx-box-padding-left);
	padding-top: var(--okx-box-padding-top);
	padding-right: var(--okx-box-padding-right);
	padding-bottom: var(--okx-box-padding-bottom);
}

.okx-box-header {
	margin-left: calc(0px - var(--okx-box-padding-left));
	margin-top: calc(0px - var(--okx-box-padding-top));
	margin-right: calc(0px - var(--okx-box-padding-right));
	padding-left: var(--okx-box-padding-left);
	padding-right: var(--okx-box-padding-right);
	border-bottom: 1px solid var(--okx-border-color);
}

.okx-box-footer {
	margin-left: calc(0px - var(--okx-box-padding-left));
	margin-bottom: calc(0px - var(--okx-box-padding-bottom));
	margin-right: calc(0px - var(--okx-box-padding-right));
	padding-left: var(--okx-box-padding-left);
	padding-right: var(--okx-box-padding-right);
	border-top: 1px solid var(--okx-border-color);
}

.order-content-wrapper ion-row {
	color: var(--ion-color-dark);
	font-weight: bold;
}

/* .checkout ion-row:last-child {
	border-top: 1px solid var(--okx-border-color);
	border-bottom: 0 none;
} */

.order-content-wrapper .basket-item-wrapper {
	border-bottom: 1px solid var(--okx-border-color);
}

ion-col.grow {
	flex-grow: 5;
}

.total-bordered-top {
	border-top: 2px solid var(--okx-border-color);
}

ion-content.route-history-details > .ion-padding > .absolute-content {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
}

ion-content.route-history-details > .ion-padding > .absolute-content > .scrollable-y {
	height: 100%;
	padding-left: 16px;
	padding-left: var(--ion-padding, 16px);
	padding-right: 16px;
	padding-right: var(--ion-padding, 16px);
	padding-bottom: 16px;
	padding-bottom: var(--ion-padding, 16px);
}

.history-details {
	background: transparent url(/static/media/history-details.0ddf4aa7.png) no-repeat 50% 100%/auto;
	background-size: 100% auto;
	z-index: 1;
}

.history-details::after {
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	display: block;
	content: '';
	background-image: linear-gradient(#fff 65%, rgba(255,255,255,0) 105%);
}

.history-details ion-grid {
	-webkit-padding-start: 0;
	        padding-inline-start: 0;
	-webkit-padding-end: 0;
	        padding-inline-end: 0;
}

.history-details ion-row {
	border-bottom: 1px solid var(--okx-border-color);
	color: var(--ion-color-dark);
	font-weight: bold;
}

.history-details ion-row:last-child {
	border-bottom: 0 none;
}

.history-details .no-border {
	border-bottom: 0 none;
}

.history-details .okx-box-footer {
	border-top: 2px solid var(--okx-border-color);
	padding-left: 0;
	margin-left: 0;
	padding-right: 0;
	margin-right: 0;
}

.history-details .okx-box-footer ion-row {
	border-bottom: 0 none;
}

:root {
	--okx-puller-height: 30px;
}

.okx-pullup {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	/*transition: all 100ms ease-out 0s;*/
	transform: translate3d(0,0,0);
}

.okx-puller {
	min-height: 30px;
	min-height: var(--okx-puller-height);
	display: flex;
	justify-content: center;
	margin: 0 auto;
	flex-direction: column;
	padding-top: 18px;
}

.okx-pullup-content {
	position: absolute;
	left: 0;
	right: 0;
	top: 35px;
	bottom: 0;
	overflow-y: auto;
}

.okx-pullup-content-top-75 {
	top: 75px;
}

.okx-pullup-content-top-110 {
	top: 110px;
}

.notch {
	width: 40px;
	height: 6px;
	background: var(--ion-color-gray);
	border-radius: 3px;
	align-self: center;
	position: absolute;
	top: 12px;
}

.gm-holder {
	position: absolute;
	left: 0; right: 0; top: 0; bottom: 0;
}

.map-holder {
	position: relative;
	height: 100%;
}

.map {
    width: 100%;
    height: 100%;
}

.gm-style .map-location-holder {
	z-index: 9999999;
}

.map-location-pullup {
	background-color: #fff;
    border-top-left-radius: var(--ion-card-radius);
	border-top-right-radius: var(--ion-card-radius);
}

.map-location-holder {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: var(--ion-color-box-holder);
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	z-index: 1;
}

.map-location-holder {
	border-radius: var(--ion-card-radius);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	background-color: #fff;
}

.map-location-content {
	flex: 1 1 auto;
	display: flex;
	justify-content: stretch;
	align-items: center;
	padding-top: 0;
}

.map-location-content > * {
	flex: 1 1 auto;
}

.map-location-content > .map-location-nav {
	flex: 0 0;
}

.map-location-content > .map-location-nav ion-button {
	font-size: 18px;
	--padding-start: 0;
	--padding-end: 0;
}

.map-location-content .swiper-slide {
	display: block;
	text-align: left;
}

.map-location-content ion-content {
	--padding-start: 10px;
	--padding-end: 10px;
	--padding-top: 0;
}

.map-location-content ion-content ion-slide > div {
	padding-bottom: 20px;
}

.map-location-content .sectiontitle {
	margin: 5px 0;
}

.location-info > div  {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.location-times {
	white-space: pre-line;
}

@supports (-webkit-overflow-scrolling: touch) {
	.map-location-content ion-content {
		position: absolute;
		left: 50px; right: 50px; top: 0; bottom: 0;
		width: auto;
	}
	.map-location-content > .map-location-nav {
		position: absolute;
		width: 50px;
	}
	.map-location-content > .map-location-nav:first-child {
		left: 0;
	}
	.map-location-content > .map-location-nav:last-child {
		right: 0;
	}
}
/*
.location-section ion-item {
    --color: var(--ion-color-dark);
    --padding-start: 0px;
    --border-color: var(--ion-separator-color);
}

.location-section h2 {
    margin-left: 10px;
}

.location-section p {
    margin-left: 10px;
    color: var(--ion-color-dark);
    font-size: 11px;
    line-height: 20px;
}
/*
.location-info > div  {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1f);
}
/*
ion-list.location-section ion-item:last-child {
    --padding-start: 0px;
    --border-width: 0px;
    --border-color: #fff;
}

.h-padded {
	padding: 0px 10px
}

.time {
    display: flex;
}

.time.time-0 {
    order: 1;
}
.time.time-1 {
    order: 3;
}
.time.time-2 {
    order: 5;
    grid-column-end: span 2;
}
.time.time-3 {
    order: 7;
    grid-column-end: span 2;
}
.time.time-4 {
    order: 9;
}
.time.time-5 {
    order: 2;
}
.time.time-6 {
    order: 4;
}

.time:nth-last-child(-n+3) {
    margin-right: 0px;
}

.day {
    flex: 0 0 35px;
}
*/


ion-grid.no-padding.social ion-col {
	padding-top: 10px;
	padding-bottom: 10px;
}

ion-grid.social ion-col:nth-child(2n-1) {
	padding-right: 10px;
}

ion-grid.social ion-col:nth-child(2n) {
	padding-left: 10px;
}

.square {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.content {
    position: absolute;
    display: flex;
    flex-flow: column;
    background-color: var(--ion-color-light);
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.soc-icon {
    display: flex;
    border-radius: 50%;
    width: 30%;
    height: 30%;
    align-items: center;
    justify-content: center;
    font-size: 40px;
}

.soc-label {
	display: block;
    padding: 8px 0 0 8px;
}

.okx-password-wrapper {
	display: flex;
	align-items: center;
	width: 100%;
}

.okx-password-wrapper .okx-password-holder {
	flex: 1 1 auto;
}

.okx-password-wrapper ion-button {
	flex: 0 0 auto;
}

.login-background {
	--background: url(/static/media/login-01.15375a03.png) transparent no-repeat 50% 100% / 100% auto;
}

.toggle {
    display: flex;
    flex-direction: column;
}

.register-background {
	--background: url(/static/media/login-01.15375a03.png) transparent no-repeat 50% 100% / 100% auto;
}

.reset-password-background {
	background: url(/static/media/login-01.15375a03.png) transparent no-repeat 50% 100%;
	background-size: 100% auto;
}

.order-categories {
	flex: 0 0 auto;
	padding: 0;
}

.order-categories h2 {
	padding: 0 25px;
}

.order-categories-inner {
	display: flex;
	flex-flow: row;
	width: 100%;
	overflow-y: auto;
}

.order-content {
	flex: 1 1 100%;
	display: flex;
	flex-flow: column;
	padding: 0 25px;
	overflow: hidden;
}

.category-button {
	--border-width: 1px;
	--border-style: solid;
	--border-color: var(--ion-color-gray);
	--color: var(--ion-color-black);
	font-weight: normal;
	--border-radius: 14px;
	text-transform: uppercase;
}

.category-button.active {
	--background: var(--ion-color-primary);
	--border-width: 0px;
	--border-style: unset;
	--border-color: transparent;
	--color: #fff;
	--background-activated:var(--ion-color-primary);
}

.order-list-items {
	overflow-y: auto;
	/* height: 100vh; */
	/* padding-bottom: 70px; */
}

.order-list-items > .list-md {
	padding-bottom: 0;
}

.order-list-items ion-item {
	--min-height: 20px;
	--inner-padding-end: 0;
}

.order-list-item {
	display: flex;
	width: 100%;
	margin-bottom: 15px;
}
.order-list-item > div {
	flex: 0 1 auto;
	margin-left: 10px;
}
.order-list-item > div:first-child {
	margin-left: 0;
	flex: 0 0 50px;
	display: flex;
	justify-content: flex-end;
}
.order-list-item > div:nth-child(2) {
	flex: 1 1 auto;
}
.order-list-item > div:nth-child(3) {
	text-align: right;
}
.item-content {
	font-size: 0.8em;
}
.item-content > h5 {
	margin: 0;
	font-size: 13px;
	font-weight: bold;
	color: var(--ion-color-dark);
}
.item-content p {
	margin: 0;
}
.item-image {
	border-radius: 5px;
	background: none transparent no-repeat 50% 50%;
	background-size: 100% auto;
	width: 50px;
	padding-top: 100%;
}
.item-price > span:first-child {
	color: var(--ion-color-secondary);
	font-size: 13px;
	font-weight: bold;
}
.item-price > span.original-price {
	text-decoration: line-through;
	font-size: 0.9em;
}
/*
.order-list-item:last-of-type {
	margin-bottom: 0;
}

.order-list-item > div:first-of-type {
	flex: 1;
	display: flex;
	align-items: center;
}

.order-list-item > div:first-of-type > img {
	border-radius: 10px;
	margin-right: 10px;
}

.order-list-item > div:first-of-type > div {
	display: flex;
	flex-flow: column;
}

.order-list-item > div:first-of-type > div > p {
	color: var(--ion-color-medium);
	font-size: 11px;
	margin:0;
}

.order-list-item > div:first-of-type > div > ion-label {
	font-size: 16px;
}

.order-list-item > p:last-of-type {
	color: var(--ion-color-workshop);
}
*/
.order-list-header {
	padding: 25px;
	margin-bottom: 10px;
}

.order-list-header ion-label {
	font-size: 25px;
	font-weight: 600;
	text-transform: none;
	text-transform: initial;
}

.order-sublist-header {
	padding-left: 0;
	position: sticky;
	position: -webkit-sticky;
	top: -1px;
	background-color: white;
	z-index: 2;
	--color:  var(--ion-color-dark);
}
.order-sublist-header ion-label {
	font-size: 15px;
	font-weight: 700;
	text-transform: none;
	text-transform: initial;

}

.order-intro-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	background: var(--ion-color-light-shade);
}

.order-intro-image-wrapper {
	flex: 1 1;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--ion-color-black);
	background: var(--ion-color-light-shade);
	width: 100%;
}

.order-intro-content {
	height: 250px;
	padding: 25px;
	border-top-left-radius: 35px;
	border-top-right-radius: 35px;
	background: var(--ion-color-medium-contrast);
}

.order-intro-content p {
	color: var(--ion-color-black);
	text-align: center;
	padding: 0 10px;
	font-size: 14px;
	margin: 25px 0;
}

.order-intro-modal > .modal-wrapper {
	height: 320px;
	width: 270px;
	border-radius: 15px;
}

.order-intro-modal > .modal-wrapper > .ion-page {
	min-width: 100%;
	padding: 15px 25px;
}

.order-intro-modal > .modal-wrapper > .ion-page ion-icon {
	position: absolute;
	right: 15px;
	font-size: 35px;
}

.order-intro-modal > .modal-wrapper > .ion-page h2 {
	font-size: 20px;
	margin-top: 4px;
}

.order-intro-modal > .modal-wrapper > .ion-page .order-intro-modal-inner {
	flex: 1 1;
	display: flex;
	flex-flow: column;
	justify-content: space-around;
}

.location-select {
	--padding-start: 0;
	margin-top: 5px;
	border-bottom: 1px solid var(--ion-color-medium);
}

.popover-content ion-item ion-label {
	margin-left: 10px;
}

.pickup-time {
	margin-top: 5px;
	border-bottom: 1px solid var(--ion-color-medium);
}

.item-content {
	margin-right: auto;
}

.item-image {
	margin-left: auto;
	min-width: 50px;
	min-height: 50px;
	width: 50px;
	height: 50px;
}
.app {
	display: grid;
	grid-template-columns: 20% 80%;
}
.navbar {
	padding: .5rem;
}
.navbar .nav-link {
	padding: .5rem;
}
.header {
	height: 80px;
	overflow: auto;
	background: #aaa;
}
.container {
	height: 500px;
	background: #ddd;
}
.gap {
	height: 500px;
}
.gap.short {
	height: 250px;
}
.gap.tall {
	height: 1000px;
}
.container.relative {
	overflow-y: auto;
}

.view-order-button {
	position: fixed;
	bottom: 10px;
	text-align: center;
	z-index: 3;
	width: 80%;
	left: 50%;
	transform: translateX(-50%);
}

@media (max-width: 700px) {
	.app {
		grid-template-columns: unset;
	}
}

@supports (-webkit-overflow-scrolling: touch) {
	/* iOS */
	.order-list-items  {
		height: 100vh;
	}
}

.incrementer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.incrementer-decrease, .incrementer-incease {
	flex: 0 0 var(--okx-incrementer-button-width);
	height: var(--okx-incrementer-button-width);
	--padding-start: 0;
	--padding-end: 0;
	--border-width: 1px;
	--border-style: solid;
	--border-color: var(--ion-color-light);
	--background: #fff;
	--background-hover: var(--ion-color-light);
	--color: var(--ion-color-primary);
}

.incrementer-quantity {
	flex: 1 1 auto;
	padding-left: var(--okx-incrementer-button-width);
	padding-right: var(--okx-incrementer-button-width);
}

.incrementer-quantity-solo {
	flex: 0 0 75px;
	text-align: center;
}

.incrementer-quantity-value {
	font-weight: bold;
}

.incrementer-note {
	font-size: var(--okx-small-text-font-size);
	color: var(--ion-color-gray);
}
/* behavior */

.item-details-image {
	position: absolute;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	/* next line is portrait-only */
	height: 100vw;
	background: transparent none no-repeat 50% 0;
	background-size: cover;
}

.item-details-card-header h2, .item-details-card-header h3, .item-details-card-header h4 {
	margin-top: 0;
}

.item-details-actions {
	position: absolute;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.item-details-back {
	position: absolute;
	position: fixed;
	left: 16px;
	top: 16px;
}

/* end behavior */

/* content reset */

ion-button.item-details-add-to-order {
	margin-top: 0;
	margin-bottom: 0;
}

/* end content reset */

/* design */

.item-details-wrapper .no-padding {
	background: var(--ion-color-light);
}

.item-details-card {
	background-color: #fff;
    border-top-left-radius: var(--ion-card-radius);
	border-top-right-radius: var(--ion-card-radius);
}

.item-details-card-header {
	padding: 10px var(--ion-padding) 10px;
	z-index: 1;
}

.item-details-card-header .title {
	font-weight: bold;
	margin: 0;
}

.item-details-card-content .sectiontitle {
	margin: 0;
}

.item-details-card-description, .item-details-card-description p, .item-details-card-description p span {
	color: var(--ion-color-gray) !important;
}

.item-details-card-price {
	text-align: right;
	font-size: var(--okx-title-font-size);
	font-weight: bold;
	color: var(--ion-color-secondary)
}

.item-details-card-content {
	padding: 0 var(--ion-padding);
	padding-bottom: 20px;
}

.item-details-actions {
	background-color: #fff;
	padding: 10px 24px 24px;
}

.item-details-actions-quantity {
	display: flex;
	justify-content: center;
	align-items: center;
}

.item-details-remove, .item-details-add {
	flex: 0 0 40px;
	height: 40px;
	--padding-start: 0;
	--padding-end: 0;
	--border-width: 1px;
	--border-style: solid;
	--border-color: var(--ion-color-light);
	--background: #fff;
	--background-hover: var(--ion-color-light);
	--color: var(--ion-color-primary);
}

.item-details-quantity {
	flex: 0 0 75px;
	text-align: center;
}

.item-details-background {
	background: var(--ion-color-gray) url(/static/media/logo-main.f1e59e8a.png) no-repeat 50% 50%/80% 30%;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	/* next line is portrait-only */
	opacity: 0.7;
	height: 100vw;
}

ion-button.item-details-add-to-order.greyed {
	--background: var(--ion-color-gray-tint);
}

@supports (-webkit-overflow-scrolling: touch) {
	/* iOS */
	.item-details-image {
		position: absolute;
	}
	.item-details-back {
		position: absolute;
	}
}

/* end design */

/* .restaurant-gradient {
	background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));
}

.restaurant-gradient::before {
	content: '';
	display: block;
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	z-index: 0;
	background-image: url(../../assets/images/restaurant.png);
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: 80% auto;
} */

ion-content div.restaurant-gradient {
	background-image: url(/static/media/restaurant.27dc9580.png);
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: 80% auto;
}

ion-content div.restaurant-gradient::before {
	content: '';
	display: block;
	position: absolute;
	left: 0; top: 0; right: 0; bottom:10%;
	z-index: 0;
	background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));
}

.restaurant-card {
	border-radius: var(--ion-card-radius);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	margin: 0;
}

.restaurant-card ion-card-content {
	padding: 30px;
	font-size: var(--ion-font-size);
}

.restaurant-card ion-card-content .restaurant-info-title {
	padding: 0 2px;
	font-weight: bold;
}

.restaurant-card ion-card-content .restaurant-info {
	padding: 15px 15px 30px;
	text-align: center;
}

.click-collect-pickers-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	background-color: black;
	opacity: 0.3;
	height: 100%;
	width: 100%;
	z-index: 100;
	pointer-events: none;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}

.click-collect-dialog {
	position: fixed;
	margin: 0 auto;
	background-color: #fff;
	width: 70%;
	height: 50%;
	border-radius: 10px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -45%);
	transition: all .3s ease-out;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	z-index: 999;
}

.click-collect-dialog.show-up {
	visibility: visible;
	opacity: 1;
	transform: translate(-50%, -50%);
	pointer-events: auto;
}

.click-collect-dialog-layout {
	height: 100%;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	padding: 5px 13px;
}

.click-collect-dialog.sc-ion-modal-md {
	border-radius: var(--border-radius);
	width: var(--width);
	min-width: var(--min-width);
	max-width: var(--max-width);
	height: var(--height);
	min-height: var(--min-height);
	max-height: var(--max-height);
	border-width: var(--border-width);
	border-style: var(--border-style);
	border-color: var(--border-color);
	background: var(--background);
	box-shadow: var(--box-shadow);
	overflow: var(--overflow);
	z-index: 10;
}

/* .click-collect-dialog.sc-ion-modal-md {
	-webkit-transform: translate3d(0,40px,0);
	transform: translate3d(0,40px,0);
	opacity: .01;
} */

.click-collect-dialog-header {
	flex: 0 1 auto;
	padding: 10px 5px;
}

.click-collect-dialog-header h3 {
	font-size: 14px;
	font-weight: 700;
	margin: 0;
}

.click-collect-dialog-closer {
	position: absolute;
	top: 0;
	right: 0;
	padding: 10px;
	cursor: pointer;
}

.click-collect-dialog-closer ion-icon {
	font-size: 20px;
}

.click-collect-dialog-content {
	flex: 1 1 auto;
	padding: 12px;
	overflow-y: auto;
}

.click-collect-dialog-content ion-label .sectiontitle {
	margin: 0;
}

.click-collect-dialog-action {
	flex: 0 1 auto;
}

.click-collect-dialog-action ion-button {
	height: 36px;
}


@-webkit-keyframes popupAnimation {
	0% {

	}

	75% {

	}

	100% {

	}
}


@keyframes popupAnimation {
	0% {

	}

	75% {

	}

	100% {

	}
}
.payment-card-input {
  border: 0;
  outline: none;
  flex: 1 1;
  padding-left: 0;
}

.scrollable-y.add-card-scroll {
	overflow: unset;
	overflow-y: unset;
}

.web-stripe-input {
  min-height: 48px;
  display: flex;
  flex: 1 1;
  align-items: center;
  border-bottom: 1px solid rgba(0,0,0,0.13);
  border-bottom: 1px solid var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
}

.web-stripe-input > .__PrivateStripeElement {
  width: 100%;
}

.web-stripe-input-active {
  border-bottom: 2px solid var(--ion-color-primary);
}

ion-list .card-list ion-item {
  padding-left: 7px;
}

.validate-content {
    margin-top: 50px;
}

ion-item.contact-number > ion-input {
	--padding-bottom: 10px!important;
	font-size: 14px;
}
ion-modal.modal-classic {
	--width: 70%;
	--height: 50%;
	--border-radius: 10px;
}

ion-modal.modal-classic .modal-classic-wrapper {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	padding: 5px 13px;
}

ion-modal.modal-classic .modal-classic-content {
	flex: 1 1 auto;
	overflow-y: auto;
	padding: 12px;
}

ion-modal.modal-classic .modal-classic-closer {
	position: absolute;
	top: 0;
	right: 0;
	padding: 10px;
	cursor: pointer;
}

ion-modal.modal-classic .modal-classic-header,
ion-modal.modal-classic .modal-classic-action {
	flex: 0 1 auto;
}

ion-modal.modal-classic .modal-classic-header {
	padding: 10px 5px;
}

ion-modal.modal-classic .modal-classic-header h3 {
	font-size: 14px;
	font-weight: bold;
	margin: 0;
}

ion-button.link.link-centered {
	margin: 0 auto;
}

.apple-pay-button {
	display: inline-block;
	background-size: 100% 60%;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	border-radius: 5px;
	padding: 0px;
	box-sizing: border-box;
	width: 100vh;
	height: 60%;
	-webkit-appearance: -apple-pay-button;
	-apple-pay-button-type: check-out;
}

.apple-pay-button-black {
    -apple-pay-button-style: black;
}

.apple-pay-button-white {
    -apple-pay-button-style: white;
}

.apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
}

.apple-button {
    --background-hover-opacity: 0;
}


/* @supports not (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display: inline-block;
        background-size: 100% 60%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
		border-radius: 5px;
		font-size: 12px;
		border-radius: 5px;
        padding: 0px;
        box-sizing: border-box;
        min-width: 200px;
        min-height: 32px;
        max-height: 64px;
    }
    .apple-pay-button-black {
        background-image: -webkit-named-image(apple-pay-logo-white);
        background-color: black;
    }
    .apple-pay-button-white {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
    }
    .apple-pay-button-white-with-line {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
        border: .5px solid black;
    } 
} */
ion-content.route-order-completed > .ion-padding > .absolute-content {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
}

ion-content.route-order-completed > .ion-padding > .absolute-content > .scrollable-y {
	height: 100%;
	padding-left: 16px;
	padding-left: var(--ion-padding, 16px);
	padding-right: 16px;
	padding-right: var(--ion-padding, 16px);
	padding-bottom: 16px;
	padding-bottom: var(--ion-padding, 16px);
}

.order-details {
	background: transparent url(/static/media/history-details.0ddf4aa7.png) no-repeat 100% 125%;
	background-size: 120% auto;
	z-index: 1;
}

.order-details::after {
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	display: block;
	content: '';
	background-image: linear-gradient(#fff 65%, rgba(255,255,255,0) 105%);
}

.order-details ion-grid {
	-webkit-padding-start: 0;
	        padding-inline-start: 0;
	-webkit-padding-end: 0;
	        padding-inline-end: 0;
}

.order-details ion-row {
	border-bottom: 1px solid var(--okx-border-color);
	color: var(--ion-color-dark);
	font-weight: bold;
}

.order-details ion-row:last-child {
	border-bottom: 0 none;
}

.order-details .okx-box-footer {
	border-top: 2px solid var(--okx-border-color);
	padding-left: 0;
	margin-left: 0;
	padding-right: 0;
	margin-right: 0;
}

.order-details .okx-box-footer ion-row {
	border-bottom: 0 none;
}

.order-status {
	width: auto;
	display: inline-block;
	font-family: var(--ion-default-font);
	font-size: var(--okx-small-text-font-size);
	font-weight: bold;
	text-transform: uppercase;
	border: 1px solid var(--ion-color-primary);
	padding: 2px 8px;
	line-height: 1;
}
ion-content.route-delivery-options {
	width: 100%;
	/* height: 100%; */
	height: calc(100vh - 79px);
  /* background-image: url(../../assets/images/refer-a-friend.jpg); */
	background-position: 50% 100%;
	background-size: cover;
	background-repeat: no-repeat;
	overflow-y: hidden;
}

/* ion-content.route-delivery-options::before {
	content: '';
	display: block;
	height: 100%;
	position: absolute;
	left: 0; top: 0; right: 0; bottom:85%;
	z-index: 1;
	background-image: linear-gradient(rgba(000,000,000,1), rgba(000,000,000,0));
} */

.delivery-options-card {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	/* left: 30px;
	right: 30px;
	top: 30px;
	bottom: 100px; */
	top: 50%;
	left: 50%;
	min-width: 280px;
	min-height: 410px;
	padding-top: 32px;
	padding-bottom: 80px;
	margin: 0;
	z-index: 2;
	--background: #fff;
	border-radius: var(--ion-card-radius);
	transform: translate(-50%, -50%);
	box-shadow: 0 0 32px rgba(0,0,0,0.25);
}

.delivery-options-card ion-card-title {
	font-size: 25px;
	font-weight: bold;
}

.delivery-options-menu {
	/* padding: 0 64px; */
	padding: 0 24px;
}

.delivery-options-menu ion-item {
	--ion-color-contrast: var(--ion-color-white) !important;
	--color: var(--ion-color-contrast);
	--background: var(--ion-color-secondary);
	--min-height: 32px;
	margin-bottom: 20px;
	border-radius: calc(var(--okx-small-radius) -  2px);
	cursor: pointer;
}

.delivery-options-menu ion-label {
	font-weight: normal;
	text-align: center;
}

.delivery-options-menu > ion-list > ion-item > ion-label {
	padding-top: 2px;
	font-weight: bold !important;
}

.web #main > ion-content.route-delivery-options {
	background-size: cover;
}

.web ion-content.route-delivery-options::after {
	content: '';
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	-webkit-backdrop-filter: blur(8px);
	        backdrop-filter: blur(8px);
}

.web .delivery-options-card {
	left: 50%;
	right: auto;
	width: var(--okx-bsmodal-width-sm);
}
.table-number-input {
	--padding-start: 0;
}
.okx-validate-input-wrapper {
	display: flex;
	width: 100%;
}

.okx-validate-input-wrapper .okx-validate-input-holder {
    flex: 1 1 auto;
    padding-bottom: 5px;
}

.okx-validate-input-wrapper .okx-validate-input-holder ion-datetime {
    --padding-start: 0;
    --padding-top: 15px;
}
.okx-validate-input-wrapper .okx-validate-input-holder p {
    font-size: 13px;
    font-weight: 500;
    margin: 21px 0 0 0;
    display: flex;
    padding-left: 2px;
    color: inherit;
}

.okx-validate-input-wrapper .okx-validate-input-holder ion-label {
    font-size: 11px;
    opacity: 1!important;
}

.okx-validate-input-holder ion-input[aria-disabled="true"] > .native-input[disabled] {
    opacity: 1!important;
}

.okx-validate-input-holder ion-input[autocapitalize="on"] {
    text-transform: capitalize;
}

.okx-validate-input-wrapper ion-button {
    flex: 1 0 auto;
    padding-top: 10px;
}

.date-picker-wrapper.modal .data-picker-input {
    padding-top: 15px;
}

.date-picker-wrapper.modal ion-label {
    font-size: 8px;
}

.date-picker-modal-label {
	font-size: 9px;
	/* color: var(--ion-color-dark); */
	margin-left: 2px;
	transition: .1s ease-in;
}
.validate-modal > .modal-wrapper {
	max-height: 72vh;
	width: 80vw;
    border-radius: 15px;
    text-align: center;
}

.validate-modal > .modal-wrapper > .ion-page {
	min-width: 100%;
	padding: 15px 25px;
	overflow: auto;
}

.validate-modal > .modal-wrapper > .ion-page h2 {
	font-size: 20px;
	margin-top: 4px;
	margin-bottom: 4px;
}

.validate-modal > .modal-wrapper > .ion-page ion-icon {
	position: absolute;
	right: 0;
	font-size: 15px;
}

.validate-modal ion-item {
	--min-height: 20px;
	--inner-padding-end: 0;
}

.modal-content {
	margin-top: 20px;
}

.icomoon {
	font-family: var(--ion-icomoon-font-family);
	font-size: 24px;
}

span > .icomoon {
	font-size: 20px;
}

.icomoon::before {
	display: block;
}

.icomoon.ico-voucher::before {
	content: '\e900';
}

.icomoon.ico-profile::before {
	content: '\e901';
}

.icomoon.ico-basket::before {
	content: '\e902';
}

.icomoon.ico-map::before {
	content: '\e903';
}

.icomoon.ico-home::before {
	content: '\e904';
}

.icomoon.ico-order::before {
	content: '\e905';
}

.icomoon.ico-social::before {
	content: '\e906';
}

.icomoon.ico-orders::before {
	content: '\e907';
}

.icomoon.ico-allergens::before {
	content: '\e908';
}

.icomoon.ico-logout::before {
	content: '\e909'
}

.icomoon.ico-feedback::before {
	content: '\e90a';
}

.icomoon.ico-radio::before {
	content: '\e90b';
}

ion-header.static {
	margin-bottom: -48px;
	z-index: 5;
}

ion-toolbar {
	--border-color: #fff;
}

ion-toolbar.primary-toolbar {
	--min-height: 80px;
	--background: var(--ion-color-white) url(/static/media/logo-main.f1e59e8a.png) no-repeat 50% 50%/auto 65%;
}

ion-header.static ion-toolbar:last-child {
	--min-height: var(--ion-toolbar-min-height);
}

ion-button.solo-button {
	--padding-start: 6px;
	--padding-end: 6px;
}

ion-button.basket-button {
	/* width: 45px; */
	height: 41px;
}

ion-button.basket-button > div > div > .badge, ion-button.basket-button > div > div > .badge-small{
	position: absolute;
    bottom: 0;
    width: 20px;
    height: 20px;
    right: -4px;
    z-index: 1;
    padding: 4px 1px;
	font-size: 12px;
	border-radius: 60px;
}

ion-button.basket-button > div > div > .badge-small {
    width: 18px;
    height: 18px;
    right: -2px;
    padding: 2px 0 0 0;
}

ion-button.basket-button > div > .basket-icon-wrapper {
	position: relative;
}

ion-button.basket-button > div > div> ion-icon {
	font-size: 30px
}

.primary-toolbar .button {
	color: var(--ion-color-dark);
}

.image-button {
	flex: 1 1;
	background: transparent;
	--ripple-color: transparent;
}

@supports (-webkit-overflow-scrolling: touch) {
	/* iOS */
	ion-toolbar.primary-toolbar {
		--background: var(--ion-color-white) url(/static/media/logo-main.f1e59e8a.png) no-repeat 50% 75%/auto 65%;
	}
}
.menu-enabled .list-md {
	padding-top: 0;
	padding-bottom: 0;
}

.drawer-menu .title-default {
	text-transform: uppercase;
	font-weight: bold;
	-webkit-padding-start: 0;
	        padding-inline-start: 0;
}

.drawer-menu ion-header, .drawer-menu ion-content, .drawer-menu ion-footer {
	--padding-start: var(--okx-drawer-padding);
	--padding-end: var(--okx-drawer-padding);
	padding-left: var(--okx-drawer-padding);
	padding-right: var(--okx-drawer-padding);
}

.drawer-menu ion-header, .drawer-menu ion-footer {
	background-color: var(--ion-color-primary);
}

.drawer-menu ion-header {
	padding-top: 10px;
	padding-bottom: 20px;
}

.drawer-menu ion-footer {
	background-image: none;
	padding-top: 5px;
	padding-bottom: 20px;
}

.drawer-menu ion-footer::before {
	background-image: none;
}

.drawer-menu .nav-logo {
	width: 100%;
	height: 32px;
	background: url(/static/media/logo-nav.5a362ba0.png) transparent no-repeat;
	background-size: contain;
}

.drawer-menu .nav-item {
	--padding-start: 0;
	--min-height: 24px;
	font-weight: bold;
}

.drawer-menu .nav-item.small-text {
	font-weight: normal;
}

.drawer-menu .nav-icon, .drawer-menu .nav-label {
	margin-top: 8px;
	margin-bottom: 8px;
}

.drawer-menu ion-header ion-toolbar:last-child{
	--border-width: 0 0 0;
}

.logo-5l {
	position: absolute;
	right: 16px;
	bottom: 8px;
}

.logo-5l > div {
	width: 100px;
	padding-top: 45%;
	background: url(/static/media/5loyalty_logo.32cd531e.png) transparent no-repeat 50% 50%;
	background-size: 100% auto;
}

ion-footer > ion-label[hidden] {
	display: block !important;
}

@media screen {
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	src: url(/static/media/regular.11eabca2.ttf);
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: normal;
	src: url(/static/media/regular-italic.a720f17a.ttf);
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	src: url(/static/media/bold.e07df86c.ttf);
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: bold;
	src: url(/static/media/bold-italic.5b44818d.ttf);
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	src: url(/static/media/black.5ebb24ee.ttf);
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 900;
	src: url(/static/media/black-italic.99682a78.ttf);
}

/** Ionic CSS Variables **/
:root {
	/** primary **/
	--ion-color-primary: #ff1a4b;
	--ion-color-primary-rgb: 255, 26, 75;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #9e1130;
	--ion-color-primary-tint: #ffafc0;

	/** secondary **/
	--ion-color-secondary: #33bf79;
	--ion-color-secondary-rgb: 114, 192, 66;
	--ion-color-secondary-contrast: #fff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #137946;
	--ion-color-secondary-tint: #8ff5c2;

	/** tertiary **/
	--ion-color-tertiary: #f79518;
	--ion-color-tertiary-rgb: 247, 149, 24;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #6b4007;
	--ion-color-tertiary-tint: #f1ba71;

	/** success **/
	--ion-color-success: #10dc60;
	--ion-color-success-rgb: 16, 220, 96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;

	/** warning **/
	--ion-color-warning: #ffce00;
	--ion-color-warning-rgb: 255, 206, 0;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255, 255, 255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;

	/** danger **/
	--ion-color-danger: #f04141;
	--ion-color-danger-rgb: 245, 61, 61;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;

	/** dark **/
	--ion-color-dark: #222;
	--ion-color-dark-rgb: 34, 34, 34;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #111;
	--ion-color-dark-tint: #444;

	/** gray **/
	--ion-color-gray: #9b9b9b;
	--ion-color-gray-rgb: 155, 155, 155;
	--ion-color-gray-contrast: #ffffff;
	--ion-color-gray-contrast-rgb: 255, 255, 255;
	--ion-color-gray-shade: #858585;
	--ion-color-gray-tint: #a1a1a1;

	/** light **/
	--ion-color-light: #ebebeb;
	--ion-color-light-rgb: 235, 235, 235;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d4d4d4;
	--ion-color-light-tint: #f1f1f1;

	/** superlight **/
	--ion-color-superlight: #f4f4f4;
	--ion-color-superlight-rgb: 244, 244, 244;
	--ion-color-superlight-contrast: #000000;
	--ion-color-superlight-contrast-rgb: 0, 0, 0;
	--ion-color-superlight-shade: #ebebeb;
	--ion-color-superlight-tint: #f9f9f9;

	/** white **/
	--ion-color-white: #fff;
	--ion-color-white-rgb: 255, 255, 255;
	--ion-color-white-contrast: #222;
	--ion-color-white-contrast-rgb: 34, 34, 34;
	--ion-color-white-shade: #d7d8da;
	--ion-color-white-tint: #f5f6f9;

	/** black **/
	--ion-color-black: #000;
	--ion-color-black-rgb: 17, 17, 17;
	--ion-color-black-contrast: #eee;
	--ion-color-black-contrast-rgb: 238, 238, 238;
	--ion-color-black-shade: #000;
	--ion-color-black-tint: #555;

	/** form **/
	--ion-color-form-label: #b0abab;
	--ion-color-form-label-dark: #1e191a;
	--ion-color-note: #cbc6c6;

	/** custom values **/
	--ion-color-title2: #1073d0;
	--ion-color-box-holder: transparent;
	--ion-separator-color: #e6e6e6;
	--ion-feedback-label: #727272;
	--ion-top-small: 5px;
	--ion-top-medium: 30px;
	--ion-feedback-star: #9ba1a5;

	/** original theme file colors **/
	--ion-headings-font-weight: 300;

	--ion-color-angular: #ac282b;
	--ion-color-communication: #8e8d93;
	--ion-color-tooling: #fe4c52;
	--ion-color-services: #fd8b2d;
	--ion-color-design: #fed035;
	--ion-color-workshop: #69bb7b;
	--ion-color-food: #3bc7c4;
	--ion-color-documentation: #b16be3;
	--ion-color-navigation: #6600cc;

	--ion-padding: 32px;
	--ion-toolbar-min-height: 48px;

	--ion-card-radius: 20px;
	--ion-item-color: #999;
	--ion-item-background: transparent;
	--ion-text-color: #555;

	/** fonts **/
	--ion-font-family: 'Roboto', Arial, Helvetica, sans-serif;
	--ion-default-font: 'Roboto', Arial, Helvetica, sans-serif;
	--ion-font-size: 13px;

	/* okx vars */
	--okx-flex-min: 5px;
	--okx-flex-spacer: 20px;
	--okx-big-label-font-size: 42px;
	--okx-big-label-color: #000;
	--okx-big-label-margin: 2px 0;
	--okx-big-label-padding: 0;
	--okx-title-font-size: 20px;
	--okx-title-color: #000;
	--okx-title-margin: 2px 0;
	--okx-title-padding: 0;
	--okx-subtitle-font-size: 16px;
	--okx-subtitle-color: #222;
	--okx-subtitle-margin: 25px 0;
	--okx-subtitle-padding: 0;
	--okx-sectiontitle-font-size: 14px;
	--okx-sectiontitle-color: #222;
	--okx-sectiontitle-margin: 20px 0;
	--okx-sectiontitle-padding: 0;
	--okx-font-size: var(--ion-font-size);
	--okx-small-text-font-size: 11px;
	--okx-note-font-size: 12px;
	--okx-error-font-size: 11px;
	--okx-error-margin: 0 0 10px;
	--okx-border-color: var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
	--okx-drawer-padding: 32px;
	--okx-box-radius: 10px;
	--okx-box-padding-left: 15px;
	--okx-box-padding-top: 15px;
	--okx-box-padding-right: 15px;
	--okx-box-padding-bottom: 15px;
	--okx-normal-text-color: var(--ion-text-color);
	--okx-light-text-color: var(--ion-color-gray);
	--okx-incrementer-button-width: 40px;
	--okx-loyalty-badge-width: 40px;
	--okx-loyalty-badge-inner-padding: 4px;
	--okx-loyalty-bar-height: 50px;
}

}
.ion-color-favorite {
	--ion-color-base: #69bb7b;
	--ion-color-base-rgb: 105, 187, 123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-twitter {
	--ion-color-base: #1da1f4;
	--ion-color-base-rgb: 29, 161, 244;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #1a8ed7;
	--ion-color-tint: #34aaf5;
}

.ion-color-google {
	--ion-color-base: #dc4a38;
	--ion-color-base-rgb: 220, 74, 56;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #c24131;
	--ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
	--ion-color-base: #23b6ea;
	--ion-color-base-rgb: 35, 182, 234;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #1fa0ce;
	--ion-color-tint: #39bdec;
}

.ion-color-facebook {
	--ion-color-base: #3b5998;
	--ion-color-base-rgb: 59, 89, 152;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #344e86;
	--ion-color-tint: #4f6aa2;
}

.ion-color-primary-shade {
	--ion-color-base: var(--ion-color-primary-shade);
	--ion-color-base-rgb: 185, 3, 11;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: var(--ion-color-primary-shade);
	--ion-color-tint: var(--ion-color-primary);
}

.ion-color-white {
	--ion-color-base: var(--ion-color-white);
	--ion-color-base-rgb: var(--ion-color-white-rgb);
	--ion-color-contrast: var(--ion-color-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-white-shade);
	--ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-black {
	--ion-color-base: var(--ion-color-black);
	--ion-color-base-rgb: var(--ion-color-black-rgb);
	--ion-color-contrast: var(--ion-color-black-contrast);
	--ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
	--ion-color-shade: var(--ion-color-black-shade);
	--ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-gray, .ion-color-grey {
	--ion-color-base: var(--ion-color-gray);
	--ion-color-base-rgb: var(--ion-color-gray-rgb);
	--ion-color-contrast: var(--ion-color-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray-shade);
	--ion-color-tint: var(--ion-color-gray-tint);
}

.ion-color-superlight {
	--ion-color-base: var(--ion-color-superlight);
	--ion-color-base-rgb: var(--ion-color-superlight-rgb);
	--ion-color-contrast: var(--ion-color-superlight-contrast);
	--ion-color-contrast-rgb: var(--ion-color-superlight-contrast-rgb);
	--ion-color-shade: var(--ion-color-superlight-shade);
	--ion-color-tint: var(--ion-color-superlight-tint);
}

.ion-color-success {
	--ion-color-base: var(--ion-color-success);
	--ion-color-base-rgb: var(--ion-color-success-rgb);
	--ion-color-contrast: var(--ion-color-success-contrast);
	--ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
	--ion-color-shade: var(--ion-color-success-shade);
	--ion-color-tint: var(--ion-color-success-tint);
}

.ion-color-warning {
	--ion-color-base: var(--ion-color-warning);
	--ion-color-base-rgb: var(--ion-color-warning-rgb);
	--ion-color-contrast: var(--ion-color-warning-contrast);
	--ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb);
	--ion-color-shade: var(--ion-color-warning-shade);
	--ion-color-tint: var(--ion-color-warning-tint);
}

.ion-color-danger {
	--ion-color-base: var(--ion-color-danger);
	--ion-color-base-rgb: var(--ion-color-danger-rgb);
	--ion-color-contrast: var(--ion-color-danger-contrast);
	--ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
	--ion-color-shade: var(--ion-color-danger-shade);
	--ion-color-tint: var(--ion-color-danger-tint);
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

/* ion reset */

.list-ios {
	margin-bottom: 0;
}

ion-modal ion-page, ion-modal .ion-page {
	min-width: 100px;
}

/* end ion reset */

h1 {
	display: block;
	color: lightgray;
	position: relative;
	text-decoration: underline;
	-webkit-text-decoration-color: red;
	        text-decoration-color: red;
}

h1::after {
	display: block;
	content: 'DON\'T USE H1 TAG ! ! !';
	color: red;
}

h2, h3, h4, h5, h6 {
	display: block;
	margin: 0;
}

p, div, li, ion-item, .normal-text {
	font-size: var(--ion-font-size);
}

p, div, li, .normal-text {
	color: var(--okx-normal-text-color);
}

.normal-text {
	font-weight: normal;
}

.small-text {
	font-size: var(--okx-small-text-font-size);
	color: inherit;
}

.light-text {
	color: var(--okx-light-text-color);
}

ion-input, ion-select, ion-datetime, .sc-ion-input-md-h, .sc-ion-input-ios-h, .datetime-text, .masked-input {
	--color: var(--ion-color-dark);
	color: var(--ion-color-dark);
}

ion-datetime.datetime-placeholder {
	opacity: 0.33;
}

.big-label {
	font-size: var(--okx-big-label-font-size);
	font-weight: bold;
	color: var(--okx-big-label-color);
	padding: var(--okx-big-label-padding);
	margin: var(--okx-big-label-margin);
}

.title {
	font-size: var(--okx-title-font-size);
	font-weight: bold;
	color: var(--okx-title-color);
	padding: var(--okx-title-padding);
	margin: var(--okx-title-margin);
}

.subtitle, h2, h3, h4, h5, h6 {
	font-size: var(--okx-subtitle-font-size);
	font-weight: bold;
	color: var(--okx-subtitle-color);
	padding: var(--okx-subtitle-padding);
	margin: var(--okx-subtitle-margin);
}

ion-label h2, ion-label h3, ion-label h4, ion-label h5, ion-label h6 {
	font-size: var(--okx-sectiontitle-font-size) !important;
}

.sectiontitle {
	font-size: var(--okx-sectiontitle-font-size);
	font-weight: bold;
	color: var(--okx-sectiontitle-color);
	padding: var(--okx-sectiontitle-padding);
	margin: var(--okx-sectiontitle-margin);
}

.title:first-child, .subtitle:first-child, .sectiontitle:first-child,
h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
	padding-top: 0;
	margin-top: 0;
}

.title2 {
	color: var(--ion-color-title2);
	margin: 40px 0 20px;
}

.pov, .poo, .relative {
	position: relative;
}

.no-margin {
	margin: 0;
}

.no-margin-bottom {
	margin-bottom: 0;
}

.no-margin-top {
	margin-top: 0;
}

.no-padding {
	padding: 0;
}

ion-grid.no-padding {
	--ion-grid-padding: 0;
}

ion-grid.no-padding ion-col {
	padding: 0;
}

.md {
	--ion-toolbar-background: var(--ion-color-primary);
	--ion-toolbar-color: #fff;
	--ion-toolbar-color-activated: #fff;
}

.box-holder {
	background-color: var(--ion-color-box-holder);
	border-radius: 5px;
	padding: 0px 15px;
	border: 1px solid var(--ion-color-light)
}

.pad10 {
	padding: 10px;
}

.pad20 {
	padding: 20px;
}

.pad40b {
	padding-bottom: 40px;
}

.pad5l {
	padding-left: 5px;
}

.pad17l {
	padding-left: 17px;
}

.box-holder ion-item {
	--padding-start: 0px;
	--border-color: var(--ion-separator-color);
}

.single-item {
	margin-bottom: 10px;
}

ion-label h2 {
	color: var(--ion-color-form-label-dark);
}

ion-note {
	color: var(--ion-color-note);
	font-size: var(--okx-note-font-size);
}

ion-label.ion-text-wrap {
	line-height: 1 !important;
}

ion-item {
	--padding-start: 0;
	--inner-padding-end: 0;
}

ion-item ion-button {
	margin-bottom: 0;
	height: auto;
}

.float-input-holder {
	--padding-start: 0px;
	--border-color: var(--ion-color-form-label)
}

.top-small {
	margin-top: var(--ion-top-small);
}

.top-medium {
	margin-top: var(--ion-top-medium);
}

.verified-content {
	width: 190px;
	margin: 0 auto;
	text-align: center;
}

ion-button {
	--border-radius: 3px;
	--box-shadow: none;
	margin-bottom: 10px;
	text-transform: none;
	letter-spacing: normal;
}

ion-button.ion-color-primary {
	--border-style: solid;
	--border-width: 2px;
	--border-color: var(--ion-color-primary);
}

ion-button.ion-color-white {
	--border-style: none;
	--border-width: 0;
	--border-color: transparent;
}

ion-button.validation {
	--background: var(--okx-color-white);
    --color: var(--ion-color-primary);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ion-color-primary);
}

ion-header ion-title {
	text-transform: uppercase;
	font-weight: bold;
}

ion-button.link  {
	/* --background: var(--okx-cancel-button-color) !important; */
	color: var(--ion-color-dark);
	font-weight: 500;
	cursor: pointer;
}

.contrast-separator {
	display: block;
	height: 0;
	line-height: 0;
	border: 1px solid rgba(255,255,255,0.3);
	margin: 16px 0;
}

.contrast-separator.contrast-separator-mini {
	margin: 12px 0;
}

.poster {
	padding-top: 65%;
	background-color: transparent;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.no-padding {
	padding: 0;
}

.text-link {
	--ion-toolbar-background: transparent;
	--background-hover: transparent;
	--background-activated: transparent;
	--background-focused: transparent;
	--color: var(--ion-color-gray);
	--color-hover: var(--ion-color-dark);
	--color-activated: var(--ion-color-dark);
	--color-focused: var(--ion-color-dark);
	--ripple-color: #fff;
	text-transform: unset;
	font-size: inherit;
}

span.link {
	color: inherit;
	cursor: pointer;
}

span.link.link-primary {
	color: var(--ion-color-primary);
	cursor: pointer;
}

.paddLR {
	padding-right: 0px;
    padding-left: 0px;
}

ion-button.link, ion-buttons ion-button.link,
.sc-ion-buttons-md-h.ion-color.sc-ion-buttons-md-s .button.link,
.ion-color .sc-ion-buttons-md-h.sc-ion-buttons-md-s .button.link,
.sc-ion-buttons-ios-h.ion-color.sc-ion-buttons-ios-s .button.link,
.ion-color .sc-ion-buttons-ios-h.sc-ion-buttons-ios-s .button.link {
	--padding-start: 0;
	--padding-end: 0;
	--padding-top: 0;
	--padding-bottom: 0;
	--background: transparent;
	--background-active: transparent;
	--background-focused: transparent;
	--background-hover: transparent;
	--border-color: transparent;
	--border-radius: 0;
	--border-style: none;
	--border-width: 0;
	--box-shadow: none;
	--ripple-color: transparent;
	--color: var(--ion-color-primary);
	--color-hover: var(--ion-color-primary-shade);
	--color-focused: var(--ion-color-primary-shade);
	--color-activated: var(--ion-color-primary-shade);
	font-size: inherit;
}

.segment-holder {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.segment-holder ion-segment {
	flex: 0 0 auto;
}

.segment-holder .segment-content {
	flex: 1 1 auto;
	overflow: hidden;
	overflow-y: auto;
}

.scrollable {
	overflow: auto;
}

.scrollable-y {
	overflow: hidden;
	overflow-y: auto;
}

.scrollable-x {
	overflow: hidden;
	overflow-x: auto;
}

.bordered, .bordered-top, .bordered-bottom, .bordered-left, .bordered-right {
	border: 1px solid var(--okx-border-color);
}

.bordered-primary {
	border: 1px solid var(--ion-color-primary);
}

.bordered-top {
	border-width: 1px 0 0;
}

.bordered-bottom {
	border-width: 0 0 1px;
}

.bordered-left {
	border-width: 0 0 0 1px;
}

.bordered-right {
	border-width: 0 1px 0 0;
}

ion-segment-button {
	height: 50px;
	border-radius: 0px;
	color: var(--ion-color-dark);
	border: 0 none;
	border-bottom: 4px solid var(--ion-color-primary-contrast);
	background-color: var(--ion-color-primary-contrast);
	font-weight: bold;
}

ion-segment-button.segment-button-checked {
	border-bottom-color: var(--ion-color-primary);
	color: var(--ion-color-primary);
}

.clickable {
	cursor: pointer;
}

.field-error {
	color: var(--ion-color-danger);
	font-size: var(--okx-error-font-size);
	margin: var(--okx-error-margin);
}

ion-checkbox {
	--border-radius: 50%;
}

.flex-col-wrapper, .flex-col-holder,
.flex-row-wrapper, .flex-row-holder {
	display: flex;
}

.flex-col-wrapper, .flex-col-holder {
	flex-direction: row;
}

.flex-col-wrapper.flex-reverse, .flex-col-holder.flex-reverse {
	flex-direction: row-reverse;
}

.flex-row-wrapper, .flex-row-holder {
	flex-direction: column;
}

.flex-row-wrapper.flex-reverse, .flex-row-holder.flex-reverse {
	flex-direction: column-reverse;
}

.flex-col-wrapper > div, .flex-col-holder > div,
.flex-row-wrapper > div, .flex-row-holder > div {
	flex: 1 1 auto;
}

.flex-col-wrapper > div.flex-min, .flex-col-holder > div.flex-min,
.flex-row-wrapper > div.flex-min, .flex-row-holder > div.flex-min {
	flex: 0 1 var(--okx-flex-min);
}

.flex-col-wrapper > div.flex-optional, .flex-col-holder > div.flex-optional,
.flex-row-wrapper > div.flex-optional, .flex-row-holder > div.flex-optional {
	flex: 1 1;
}

.flex-col-wrapper > div.flex-spacer, .flex-col-holder > div.flex-spacer,
.flex-row-wrapper > div.flex-spacer, .flex-row-holder > div.flex-spacer {
	flex: 0 1 var(--okx-flex-spacer);
}

.absolute-content {
	position:absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	-webkit-padding-start: 16px;
	        padding-inline-start: 16px;
	-webkit-padding-start: var(--ion-padding, 16px);
	        padding-inline-start: var(--ion-padding, 16px);
	-webkit-padding-end: 16px;
	        padding-inline-end: 16px;
	-webkit-padding-end: var(--ion-padding, 16px);
	        padding-inline-end: var(--ion-padding, 16px);
	padding-left: 16px;
	padding-left: var(--ion-padding, 16px);
    padding-right: 16px;
    padding-right: var(--ion-padding, 16px);
    padding-top: 16px;
    padding-top: var(--ion-padding, 16px);
    padding-bottom: 16px;
    padding-bottom: var(--ion-padding, 16px);
}

.absolute-content > div {
	position: relative;
	z-index: 1;
}

.centered, .centered * {
	text-align: center;
	justify-content: center;
	align-items: center;
	align-self: center;
	justify-self: center;
}

.righted, .righted * {
	text-align: right;
	justify-content: flex-end;
	align-items: flex-end;
	align-self: flex-end;
	justify-self: flex-end;
}

.righted, .righted * .grow {
	flex-grow: 0;
}  

.righted, .righted * .instruction-grow {
	flex-grow: 1;
}

.mixed-right, .mixed-right * {
	text-align: right;
	justify-content: flex-end;
	align-items: center;
	align-self: center;
	justify-self: flex-end;
}

.mixed-left, .mixed-left * {
	text-align: left;
	justify-content: flex-start;
	align-items: center;
	align-self: center;
	justify-self: flex-start;
}

.lefted, .lefted * {
	text-align: left;
	justify-content: flex-start;
	align-items: flex-start;
	align-self: flex-start;
	justify-self: flex-start;
}

.mixed-left , .mixed-left * .grow{ 
	flex-grow: 0.5;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.uppercase {
	text-transform: uppercase;
}

ion-badge.small {
	font-size: 7px;
	--padding-start: 2px;
	--padding-end: 2px;
	--padding-top: 2px;
	--padding-bottom: 2px;
	border-radius: 50%;
	min-width: 11px;
	text-transform: none;
	position: absolute;
}

.card-thumb {
	display: inline-block;
	width: 55px;
	height: 35px;
	/*background-color: var(--ion-color-light);*/
	position: relative;
	border-radius: 3px;
	border: 1px solid var(--ion-color-light);
}

.card-thumb.visa::after {
	display: block;
	position: absolute;
	left: 0; top: 10px; right: 0; bottom: 0;
	content: 'VISA';
	color: #2566af;
	text-align: center;
	font-weight: 900;
	font-style: italic;
}

.card-thumb.mc::before {
	display: block;
	position: absolute;
	left: 5px; top: 5px;;
	width: 26px;
	height: 26px;
	content: '';
	text-shadow: 1px 1px rgba(0,0,0,0.3);
	border-radius: 50%;
	background-color: #c00;
}

.card-thumb.mc::after {
	display: block;
	position: absolute;
	left: 0; top: 2px; right: 0; bottom: 0;
	content: 'MasterCard';
	color: #fff;
	font-size: 8px;
	padding-top: 11px;
	text-align: center;
	font-weight: bold;
	font-style: italic;
	text-shadow: 1px 1px rgba(0,0,0,0.3);
	/*background-image: radial-gradient(circle at 36px 16px, #f90 0px 12px,transparent 13px);*/
	background-image: radial-gradient(circle at 36px 16px, rgba(255,153,0,0.7) 0px 12px,transparent 13px);
}

input:-webkit-autofill,
textarea:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

